<div class="container scotiabank-general-config" >
  <nav class="navbar  scotiabank-toolbar mx-auto "   >
    <div class="align-content-start p-1">
      <a class="navbar-brand "  style="color: white" >Santander demo</a>
    </div>
    <div class="p-1">
      <button type="button" class="btn align-content-end"   style="color: white">Salir</button>
    </div>
  </nav>

  <div class=" mt-3" >
    <img src="/assets/bank_logo.png"  class="mx-auto d-block img-fluid" alt="Responsive image" style="width: 200px">

  </div>



  <div class="row mt-3 justify-content-center" >
    <div class="col-6 col-lg-6 col-md-6 col-sm-6 col-xl-6  text-center"  (click)="openSocialNetwork()">
      <img class=" img-fluid rounded p-3 scotiabank-img" src="assets/ic_scotia_web.svg"
           alt="Card image cap" >
      <p class="mt-1"> Perfil Sucursal</p>

    </div>
    <div class="col-6 col-lg-6 col-md-6 col-sm-6 col-xl-6 text-center "  (click)="openSocialNetwork()" >
      <img class="img-fluid rounded p-3 scotiabank-img" src="assets/ic_scotia_online.svg"
           alt="Card image cap"
      >
      <p class="mt-1">Perfil corporativo</p>

    </div>
  </div>

  <div class="row mt-2 justify-content-center" >
    <div class="col-6 col-lg-6 col-md-6 col-sm-6 col-xl-6  text-center" (click)="ClickUrl('https://www.santander.com.mx/')" >
      <img class=" img-fluid rounded p-3 scotiabank-img" src="assets/public_white.svg"
           alt="Card image cap">
      <p class="mt-1"> Santander web</p>

    </div>
    <div class="col-6 col-lg-6 col-md-6 col-sm-6 col-xl-6 text-center" (click)="ClickUrl('https://www.santander.com.mx/MiSitioPrivado/acceso/codigo-cliente')">
      <img class="img-fluid rounded p-3 scotiabank-img" src="assets/ic_card_credit.svg"
           alt="Card image cap"
      >
      <p class="mt-1">Santander en línea </p>

    </div>
  </div>


  <div class="row mt-4 justify-content-center mb-5" >
    <div class="col-6 col-lg-6 col-md-6 col-sm-6 col-xl-6  text-center" (click)="ClickUrl('https://www.google.com/maps?q=sucursales+santander&rlz=1C5CHFA_enMX887MX889&um=1&ie=UTF-8&sa=X&ved=2ahUKEwih5c6IjuvvAhVPSq0KHb6UCq4Q_AUoAXoECAEQAw')">
      <img class=" img-fluid rounded p-3 scotiabank-img" src="assets/ic_sucursal.svg"
           alt="Card image cap">
      <p class="mt-1"> Sucursales</p>

    </div>
    <div class="col-6 col-lg-6 col-md-6 col-sm-6 col-xl-6 text-center"  >
      <img class="img-fluid rounded p-3 scotiabank-imgv2" src="assets/transparent.png"
           alt="Card image cap"
      >
      <p class="mt-1"></p>

    </div>
  </div>



  <button type="button" class="btn btn-danger  btn-lg btn-block" style="background: #EE3124" (click)="openCall()">Continuar llamada</button>




  <ngb-carousel *ngIf="images" >
    <ng-template ngbSlide>
      <div class="wrapper">
         <img src="assets/banner1.png" class="" style="width: 100%"  height="150px" alt="Random first slide">
      </div>
      <div class="carousel-caption">
        <h3>First Slide</h3>
        <p> Angular 10 Carousel Example </p>
      </div>
    </ng-template>
    <ng-template ngbSlide>
      <div class="wrapper">
        <img src="assets/banner2.png"  style="width: 100%" height="150px"   alt="Random second slide">
      </div>
      <div class="carousel-caption">
        <h3>Second Slide</h3>
        <p> Check out Techiediaries</p>
      </div>
    </ng-template>

  </ngb-carousel>

</div>





