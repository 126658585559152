import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-dialog-network-social',
  templateUrl: './dialog-network-social.component.html',
  styleUrls: ['./dialog-network-social.component.scss']
})
export class DialogNetworkSocialComponent implements OnInit {

  @Input() modalTitle;

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {
  }

  ClickUrl(url: string): void {
    //window.open(url, '_blank');
    this.activeModal.close(url);

  }
}
