import {Question} from './Question';
import {TypeQuestion} from './type-question.enum';

export class Utils {
  static  generateQuestion(): Question[]{

    return [
      {
        answers: [
          {
            id: '2efb34d3-3ca9-4380-891b-9d27f1f99943',
            isCorrect: true,
            text: '2106'
          },
          {
            id: '20992322-47c2-45b8-975b-d6a9bef565ff',
            isCorrect: true,
            text: '0101'
          },
          {
            id: '008650d3-27cb-4a50-a39d-4f2bdc30bb2d',
            isCorrect: true,
            text: '0301'
          }
        ],
        id: 'd46d230f-b4ef-4efc-936f-261527c3ed18',
        order: 1,
        text: 'Fecha de nacimiento. Cuatro dígitos, empezando por día y mes ',
        typeQuestion: TypeQuestion.TEXT
      },
      {
        answers: [
          {
            id: '16dca233-4bad-4311-8de9-4befe21485fc',
            isCorrect: true,
            text: '1991'
          },
          {
            id: '110ac184-2475-4ba7-b211-2176820233d6',
            isCorrect: true,
            text: '2020'
          },
          {
            id: '5698693c-2431-43a1-adba-b298bd192e60',
            isCorrect: true,
            text: '2021'
          }
        ],
        id: '1c8924c7-aa86-40fa-9270-adf96d353af8',
        order: 2,
        text: 'Año de nacimiento,Cuatro dígitos ',
        typeQuestion: TypeQuestion.TEXT
      },
      {
        answers: [
          {
            id: '98211ae9-0dc1-437d-80ad-f1e7ec06505a',
            isCorrect: true,
            text: '2020'
          },
          {
            id: '09d9d27f-4ad3-4001-bbd6-7b4d88b709fb',
            isCorrect: true,
            text: '2021'
          },
          {
            id: 'db3a007d-dc95-4edb-81e6-ec3ee5712d88',
            isCorrect: true,
            text: '2019'
          }
        ],
        id: '16b40be0-d09a-409e-aacc-eefdca31ead4',
        order: 3,
        text: 'Fecha de ingreso al banco. \n Cuatro dígitos, empezando por día y mes ',
        typeQuestion: TypeQuestion.TEXT
      },
      {
        answers: [
          {
            id: '58dd04c4-a223-4cf4-943d-a48ca436b621',
            isCorrect: true,
            text: '1980'
          },
          {
            id: 'd96f9973-556b-4f67-bc28-98df7684b7c7',
            isCorrect: true,
            text: '1960'
          },
          {
            id: '0139e1de-17eb-4a65-a6eb-da6b2e6b8d16',
            isCorrect: true,
            text: '1970'
          }
        ],
        id: '3930198f-1c7b-4986-82f8-22a63122c6f5',
        order: 4,
        text: 'Fecha de nacimiento de su mamá. \n Cuatro dígitos, empezando por día y mes ',
        typeQuestion: TypeQuestion.TEXT
      },
      {
        answers: [
          {
            id: '416f95a4-af1a-4576-b524-e4b53a9e450d',
            isCorrect: true,
            text: '1980'
          },
          {
            id: 'f22722d0-0756-463e-936c-c9acd1bb65e3',
            isCorrect: true,
            text: '1960'
          },
          {
            id: '1f9099f8-4eb6-45e5-b036-ff591c078fde',
            isCorrect: true,
            text: '1970'
          }
        ],
        id: 'a8186044-a95b-4007-a0b1-cb1f625863c5',
        order: 5,
        text: 'Fecha de nacimiento de su papá.  \nCuatro dígitos, empezando por día y mes ',
        typeQuestion: TypeQuestion.TEXT
      },
      {
        answers: [
          {
            id: 'bae1bd70-7d0e-410b-a61c-3d62137a8847',
            isCorrect: true,
            text: '2000'
          },
          {
            id: '8ab0793c-7a3f-473e-98ae-6acf1f5660ce',
            isCorrect: true,
            text: '1990'
          },
          {
            id: '86a879cc-a598-430a-a204-9c27716f01c1',
            isCorrect: true,
            text: '1995'
          }
        ],
        id: '6edad173-d71b-4b3c-8aa6-83248f5e89c8',
        order: 6,
        text: 'Fecha de nacimiento de su primer hijo.\n  Cuatro dígitos, empezando por día y mes ',
        typeQuestion: TypeQuestion.TEXT
      },
      {
        answers: [
          {
            id: 'd8c936f2-2747-471e-9dfd-ce195d911978',
            isCorrect: true,
            text: '2000'
          },
          {
            id: '5f853b71-d16c-422c-921b-c051eb29cc1d',
            isCorrect: true,
            text: '2010'
          },
          {
            id: '95d02a5b-68b5-4d6f-a561-725fccafeaf7',
            isCorrect: true,
            text: '2015'
          }
        ],
        id: '172eba1f-62c9-41e9-87fc-83fdce9dffef',
        order: 7,
        text: 'Fecha de matrimonio. Cuatro dígitos,\n empezando por día y mes ',
        typeQuestion: TypeQuestion.TEXT
      },
      {
        answers: [
          {
            id: 'd23436c3-0fca-41ea-a99e-d53502d8dd80',
            isCorrect: true,
            text: '0121'
          },
          {
            id: '670e9697-a81f-4e96-804c-e3f4d9288cb4',
            isCorrect: true,
            text: '0121'
          },
          {
            id: 'a63b035d-17ef-4fc5-923f-9247cda9b0ae',
            isCorrect: true,
            text: '0221'
          },
          {
            id: 'ee8e112c-d2ce-4f90-bc8e-830d7189de7d',
            isCorrect: true,
            text: '0321'
          },
          {
            id: '00c63520-1dcf-49b6-b55a-54db68513028',
            isCorrect: true,
            text: '0421'
          },
          {
            id: 'cce1ca3b-06f1-449b-9823-dcb82f502b02',
            isCorrect: true,
            text: '0521'
          },
          {
            id: '0540a1e3-1c64-4d13-8d66-6392317895f8',
            isCorrect: true,
            text: '0621'
          },
          {
            id: '6af68952-0c04-44b2-aa18-2158db633f54',
            isCorrect: true,
            text: '0721'
          },
          {
            id: 'bba3ba29-53d8-41fa-a326-cb13eff9c53c',
            isCorrect: true,
            text: '0821'
          },
          {
            id: 'a1b09a08-ba84-4bc0-950f-1ab4fe185610',
            isCorrect: true,
            text: '0921'
          },
          {
            id: '008ab50f-b50c-47e5-bb05-a320f24f5b55',
            isCorrect: true,
            text: '1021'
          },
          {
            id: '506357b4-1b42-4b4c-b4d5-12051e994a98',
            isCorrect: true,
            text: '1121'
          },
          {
            id: 'd5b152b0-bbc5-4a92-aece-6d1b27d8695b',
            isCorrect: true,
            text: '1221'
          }
        ],
        id: 'c66fe8cf-4163-4ce2-a79f-95012f18140b',
        order: 8,
        text: 'Ingresa la fecha de vencimiento de tu tarjeta de crédito, \n utilizando 2 digitos para el mes y 2 para el año',
        typeQuestion: TypeQuestion.TEXT
      },
      {
        answers: [
          {
            id: 'a57b063d-4d4a-484a-a22c-66d180062cac',
            isCorrect: true,
            text: 'Opcion-1'
          },
          {
            id: '6a2c493e-783f-4821-af65-04560ccd0062',
            isCorrect: false,
            text: 'Opcion-2'
          },
          {
            id: '401c8961-019e-4738-a6aa-ac0ce12c40fe',
            isCorrect: true,
            text: 'Opcion-3'
          },
          {
            id: '0a692144-420e-4aaf-9a5b-cb9d1dbf4a8d',
            isCorrect: false,
            text: 'Opcion-4'
          }
        ],
        id: 'f7ac33db-d383-482c-aa63-918110edaba1',
        order: 8,
        text: 'Pregunta opcion multiple',
        typeQuestion: TypeQuestion.OPTIONAL_MULTIPLE
      },
      {
        answers: [
          {
            id: '9cd5aaa1-ef29-4ef1-9bad-9b97187128c7',
            isCorrect: true,
            text: 'TEXTO'
          }
        ],
        id: '0833cf8d-cc2f-4ec6-8b2f-782e9fea0702',
        order: 10,
        text: 'Pregunta - Solo texto',
        typeQuestion: TypeQuestion.TEXT
      }
    ];
  }

  static  generateQuestionExp(): Question[]{

    return [
      {
        answers: [
          {
            id: '1ae6e3f5-3e2e-4d8c-ab54-aa6a20f6b11f',
            isCorrect: true,
            text: 'Z999999'
          },
          {
            id: '0ec9db3b-3506-454c-9440-292f710428bd',
            isCorrect: true,
            text: 'Z100000'
          },
          {
            id: 'ee29b770-e3c1-4c86-93ee-5c0dde75f858',
            isCorrect: true,
            text: 'Z110000'
          }
        ],
        id: 'b193b639-2f19-439f-8094-2b95dacc7c48',
        order: 1,
        text: 'Ingresa tu expediente ',
        typeQuestion: TypeQuestion.TEXT
      }
    ];
  }
}
