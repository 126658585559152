<div *ngIf="isVisible">


<mat-label>{{question.text}}</mat-label>
<br>
<mat-radio-group aria-label="Select an option" [(ngModel)]="checkModel">
  <mat-radio-button [value]="question.answers[0].text">{{question.answers[0].text}}</mat-radio-button>
  <br>
  <mat-radio-button [value]="question.answers[1]  .text">{{question.answers[1].text}}</mat-radio-button>
</mat-radio-group>
</div>
