
<div class="modal-header">
  <h4 class="modal-title">
Atención
  </h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
  <br>
  <br>


</div>
<div class="modal-body">

  <div class="container" >

    <p>{{message}}</p>

  </div>

  <button mat-raised-button style="background: #EE3124; color: white" (click)="OK()"> OK</button>


</div>

