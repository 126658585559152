import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MainComponent } from './main/main.component';
import {MatSliderModule} from '@angular/material/slider';
import {MatToolbarModule} from '@angular/material/toolbar';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {MatGridListModule} from '@angular/material/grid-list';
import { DialogNetworkSocialComponent } from './dialog-network-social/dialog-network-social.component';
import {EnrolmentModule} from './enrolment/enrolment.module';
import {MatInputModule} from '@angular/material/input';
import {CommonModule} from '@angular/common';

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    DialogNetworkSocialComponent,

  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatInputModule,
    MatSliderModule,
    MatToolbarModule,
    NgbModule,
    MatGridListModule,
    EnrolmentModule,

  ],
  entryComponents: [
    DialogNetworkSocialComponent
  ],
  providers: [],
  bootstrap: [AppComponent],

})
export class AppModule { }
