<div class="modal-header">
  <h4 class="modal-title">{{modalTitle}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">


  <div class="container" >
    <div class="row mt-3 justify-content-center" >
      <div class="col-6 col-lg-6 col-md-6 col-sm-6 col-xl-6  text-center"  (click)="ClickUrl('ER')">

        <img class=" img-fluid rounded p-3 scotiabank-img" src="assets/settings_accessibility.svg"
             alt="Card image cap" >
        <p class="mt-1"> Nuevo usuario</p>

      </div>
      <div class="col-6 col-lg-6 col-md-6 col-sm-6 col-xl-6 text-center" (click)="ClickUrl('ERE')" >
        <img class="img-fluid rounded p-3 scotiabank-img" src="assets/folder.svg"
             alt="Card image cap"
        >
        <p class="mt-1">Ingresar</p>

      </div>
    </div>


  </div>

</div>

