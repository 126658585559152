import {Answer} from './Answer';
import {TypeQuestion} from './type-question.enum';

export class Question {
  answers: Answer[];
  id: string;
  order: number;
  text: string;
  typeQuestion: TypeQuestion;
}
