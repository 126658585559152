import { Component, OnInit } from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-dialog-generate-password',
  templateUrl: './dialog-generate-password.component.html',
  styleUrls: ['./dialog-generate-password.component.scss']
})
export class DialogGeneratePasswordComponent implements OnInit {
  validate = new FormControl('', [Validators.required,
    Validators.minLength(4)]);

  constructor(public activeModal: NgbActiveModal) { }
  getErrorMessage(): string {
    if (this.validate.hasError('minlength')) {
      return 'Tiene que ser maximo 4 numeros';
    }
    if (this.validate.hasError('pattern')) {
      return 'Solo se aceptan números ';
    }

    return this.validate.hasError('required') ? 'Valor requerido' : '';
  }
  ngOnInit(): void {
  }

  validateCode(): void {
    this.activeModal.close();

  }
}
