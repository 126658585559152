import {AfterViewInit, Component, Input, OnInit, Output, ViewChild, ViewChildren} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Question} from '../model/Question';
import {TypeQuestion} from '../model/type-question.enum';
import {QuestionSimpleComponent} from '../question-simple/question-simple.component';
import {QuestionMultipleComponent} from '../question-multiple/question-multiple.component';
import {QuestionTwoComponent} from '../question-two/question-two.component';
import {EventEmitter} from 'events';

@Component({
  selector: 'app-dialog-enrolment',
  templateUrl: './dialog-enrolment.component.html',
  styleUrls: ['./dialog-enrolment.component.scss']
})
export class DialogEnrolmentComponent implements OnInit , AfterViewInit{
  constructor(public activeModal: NgbActiveModal) {




  }
  @Input() questions: Question[];
  @Input() answareCorrect = 3;
  @Input() opportunities = 3;

  @ViewChild('question_simple') questionSimple: QuestionSimpleComponent;
  @ViewChild('question_multiple') questionMultiple: QuestionMultipleComponent;
  @ViewChild('question_one_selection') questionOneSelection: QuestionTwoComponent;


  private currentQuestion: Question;
  failsQuestion: Question[] = [];
  private successQuestion: Question[] = [];
  private totalopportunities = 0;
   indexQuestion = 0;
  simpleQuestionIsVisible = false;
  currentQuestionSimple: Question = new Question();

  isVisibleQuestionMultiple = false;
  currentQuestionMultiple: Question = new Question();

  isVisibleQuestionTwo = false;
  currentQuestionTwo: Question = new Question();
  ngOnInit(): void {
  }

  ngAfterViewInit(): void {

    setTimeout(() => {
      this.setup();
    });


  }
  private  setup(): void {
    this.nextQuestion(true);
  }



    nextQuestion(isFirst: boolean = false): void {


    if (!isFirst){
      this.validateQuestion();
    }
    if (this.totalopportunities === this.opportunities){


      this.activeModal.close({success: this.successQuestion, fail: this.failsQuestion, isSuccess: false});

      return;
    }
    if (this.successQuestion.length === this.answareCorrect){
      this.activeModal.close({success: this.successQuestion, fail: this.failsQuestion, isSuccess: true});


      return;
    }
    if (this.questions.length === 0) {
      this.activeModal.close({success: this.successQuestion, fail: this.failsQuestion, isSuccess: false});


      return;
    }

    if (this.questions.length === this.indexQuestion) {
      this.activeModal.close({success: this.successQuestion, fail: this.failsQuestion, isSuccess: false});

      return;
    }

    this.currentQuestion = this.questions[this.indexQuestion];
    this.createQuestion();
    this.indexQuestion = this.indexQuestion + 1;
    // this.indexQuestion++;


  }

  private  validateQuestion(): void {
    const isValidate = false;

    switch (this.currentQuestion.typeQuestion) {
      case TypeQuestion.NONE:
        this.failsQuestion.push(this.currentQuestion);
        this.totalopportunities++;
        break;
      case TypeQuestion.OPTIONAL_MULTIPLE:
        if (this.questionMultiple.validate()){
          this.successQuestion.push(this.currentQuestion);
        }else {
          this.failsQuestion.push(this.currentQuestion);
          this.totalopportunities++;
        }

        break;
      case TypeQuestion.TEXT:
        if (this.questionSimple.validate()){
          this.successQuestion.push(this.currentQuestion);
        }else {
          this.failsQuestion.push(this.currentQuestion);
          this.totalopportunities++;
        }
        break;
      case TypeQuestion.ONE_SELECTION:
        if (this.questionOneSelection.validate()){
          this.successQuestion.push(this.currentQuestion);
        }else {
          this.failsQuestion.push(this.currentQuestion);
          this.totalopportunities++;
        }
        break;
    }
  }

  private  createQuestion(): void{
    this.isVisibleQuestionMultiple = false;
    this.simpleQuestionIsVisible = false;
    this.isVisibleQuestionTwo = false;
    switch (this.currentQuestion.typeQuestion) {
      case TypeQuestion.NONE:
        break;
      case TypeQuestion.OPTIONAL_MULTIPLE:
        this.isVisibleQuestionMultiple = true;
        this.currentQuestionMultiple = this.currentQuestion;
        break;
      case TypeQuestion.TEXT:
        this.simpleQuestionIsVisible = true;
        this.currentQuestionSimple = this.currentQuestion;
        break;
      case TypeQuestion.ONE_SELECTION:
        this.isVisibleQuestionTwo = true;
        this.currentQuestionTwo = this.currentQuestion;
        break;
    }

  }


}
