import { Component, OnInit } from '@angular/core';
import {NgbCarouselConfig, NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {DialogNetworkSocialComponent} from '../dialog-network-social/dialog-network-social.component';
import {DialogEnrolmentComponent} from '../enrolment/dialog-enrolment/dialog-enrolment.component';
import {Utils} from '../enrolment/model/Utils';
import {DialogGeneratePasswordComponent} from '../enrolment/dialog-generate-password/dialog-generate-password.component';
import {DialogInformationComponent} from '../enrolment/dialog-information/dialog-information.component';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {

  constructor(config: NgbCarouselConfig,
              private modalService: NgbModal
  ) {
    config.interval = 2000;
    config.keyboard = true;
    config.pauseOnHover = true;

  }
  images = ['https://scotiabankfiles.azureedge.net/scotia-bank-mexico/images/redesign/tiles/fiu-01-35.jpg',
    'https://scotiabankfiles.azureedge.net/scotia-bank-mexico/images/redesign/tiles/tile-instagram.jpg',
    'https://scotiabankfiles.azureedge.net/scotia-bank-mexico/images/redesign/tiles/tile-dll.jpg'];
  ngOnInit(): void {
  }
  ClickUrl(url: string): void{
     window.open(url, '_blank');
     /*let modalRef = this.modalService.open(DialogGeneratePasswordComponent);
     modalRef.result.then(() => {
      modalRef = this.modalService.open(DialogInformationComponent);
      modalRef.componentInstance.message = 'Tu nueva contraseña de desbloqueo ha sido actualizada. Gracias por llamar, ' +
        'te enviaremos un número de expediente.';
      modalRef.result.then(() => {

      });


    });*/
  }
  openEnrolment(): void{

    this.openQuestion(Utils.generateQuestion()).result.then(c => {
      if (c.isSuccess){
        this.openPasswordChange().result.then(x => {

          this.openDialogInfo('Tu nueva contraseña de desbloqueo ha sido creada. ' +
            'Te enviaremos un número de expediente. Gracias por llamar');
        });

      }else{
        this.openDialogInfo('Tus respuestas son incorrectas, gracias por llamar.');
      }
    });

  }

  openDialogInfo(text: string): NgbModalRef{
    const m = this.modalService.open(DialogInformationComponent);
    m.componentInstance.message = text;
    return m;
  }
  openPasswordChange(): NgbModalRef {
   return  this.modalService.open(DialogGeneratePasswordComponent);
  }

  openQuestion(question: any, answareCorrect= 3): NgbModalRef {
    const modalRef = this.modalService.open(DialogEnrolmentComponent);
    modalRef.componentInstance.questions = question;
    modalRef.componentInstance.answareCorrect = answareCorrect;

    return modalRef;
  }
  openEnrolmentExp(): void{

    this.openQuestion(Utils.generateQuestionExp(), 1).result.then(
      (value) => {
        if (value.isSuccess){
          this.openPasswordChange().result.then(c => {
            this.openDialogInfo('Tu nueva contraseña de desbloqueo ha sido actualizada. Gracias por llamar,' +
              'te enviaremos un número de expediente.').result.then((v) => {
               this.openQuestion(Utils.generateQuestion()).result.then(s => {
                 this.openDialogInfo('Se ha concluido tu proceso de autenticación correctamente');

               });
            });
          });
        }else{
          this.openDialogInfo('Tus respuestas son incorrectas, gracias por llamar.');
        }
      }
    );

  }
  openSocialNetwork(): void {
    const modalRef = this.modalService.open(DialogNetworkSocialComponent);
    modalRef.componentInstance.modalTitle = 'Escoge una opción';
    modalRef.result.then(value => {

      if (value === 'ER'){
       this.openEnrolment();
      }else if (value === 'ERE') {
        this.openEnrolmentExp();

      }

    });
  }

  openCall(): void {
    window.open('tel:5551741101', '_system');

  }
}
