import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Question} from '../model/Question';
import {Answer} from '../model/Answer';

@Component({
  selector: 'app-question-multiple',
  templateUrl: './question-multiple.component.html',
  styleUrls: ['./question-multiple.component.scss']
})
export class QuestionMultipleComponent implements OnInit, OnChanges {

  @Input()
  question: Question = new Question();
  @Input()
  isVisible = false ;
  private listChecked: Answer[] = [];
  inputModel;

  constructor() { }

  ngOnInit(): void {
  }
  public validate(): boolean{

    this.question.answers.filter(value => value.isCorrect).forEach(value => {

      const items = this.question.answers.find(it => it.id === value.id);
      if (items === undefined){
        return false;
      }

    });
    return true;

  }

  listChange(item: Answer, isCheck: boolean): void {
    if (isCheck) {
    this.listChecked.push(item);
    }
    else {
      this.listChecked.splice(this.listChecked.indexOf(item), 1);
    }

  }

  ngOnChanges(changes: SimpleChanges): void {
    this.listChecked = [];
  }
}
